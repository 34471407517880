// @import "../node_modules/bootstrap/scss/variables";

$primary:       #00C7CE;
$secondary:     #ff58ac;
$success:       #AAE174;
$info:          #AA74E1;
$warning:       #FFE58D;
$danger:        #FF5C56;

.masthead {
  position:sticky; 
  top: 4rem;
  overflow: hidden;
  display: none;
}
// @media (min-width: 768px) {
//   .masthead {
//       position: -webkit-sticky;
//       position: sticky;
//       top: 4rem;
//   }
// }
// @media (max-width: 768px) {
//   .masthead {
//       display: none;
//   }
// }
.vr { height:100%}
#content {
  grid-template-columns: 4fr 8fr;
  gap: 3rem
}
.posts-container {
  max-width:760px
}
.text-nipon {
  text-orientation: upright;
  writing-mode: vertical-lr;
  max-height: 300px;
}
.icon-flipped {
  transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
}
.my-glyphicon {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;
$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;
$font-sizes: (
  1: $font-size-base * 1.1875,
  2: $font-size-base * 1.125,
  3: $font-size-base * 1.0625,
  4: $font-size-base,
  5: $font-size-base * .9375,
  6: $font-size-sm
);

@import "../node_modules/bootstrap/scss/bootstrap";


@include media-breakpoint-up(lg) {
  .masthead {
      display: block;
  }
}